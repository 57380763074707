import React, { useState } from "react"

import { graphql } from "gatsby"
import { MultiPageFormProvider } from "../components/ContextProviders/MultiPageFormProvider"
import ElevatorContent from "../components/ElevatorContent"
import ElevatorHero from "../components/ElevatorHero"
import ElevatorForm from "../components/elevatorInquiry/ElevatorForm"
import Layout from "../components/layout"
import PageEndBanner from "../components/PageEndBanner"
import Seo from "../components/seo"

const Controller = ({ pageContext, data: { controller, miscString } }) => {
  const [open, setOpen] = useState(false)
  return (
    <Layout pageData={pageContext}>
      <Seo
        title={controller.title}
        seoTitle={controller.seo?.seoTitle}
        seoDescription={controller.seo?.seoDescription}
        seoImage={controller.seo?.image?.seoImageUrl}
      />
      <ElevatorHero
        isHero
        gradient={true}
        description={controller.description}
        tag={controller.tag}
        title={controller.title}
        cta={miscString.nodes[0]?.getInquiry}
        open={open}
        setOpen={() => setOpen(!open)}
        heroimage={controller.hero?.gatsbyImageData}
        bottomImage
        isDark
      />
      <MultiPageFormProvider>
        <ElevatorForm open={open} setOpen={() => setOpen(false)} />
      </MultiPageFormProvider>
      {controller.content?.value && (
        <ElevatorContent structuredData={controller.content} />
      )}
      <PageEndBanner onClick={() => setOpen(!open)} />
    </Layout>
  )
}

export default Controller

export const query = graphql`
  query controllerQuery($id: String, $locale: String) {
    controller: datoCmsController(
      originalId: { eq: $id }
      locale: { eq: $locale }
    ) {
      id
      title
      locale
      slug
      description
      tag
      hero {
        gatsbyImageData(height: 400)
      }
      content {
        value
        links {
          __typename
          id: originalId
          title
          slug
        }
        blocks {
          ... on DatoCmsFeaturelistinlinebig {
            id: originalId
            __typename
            feature {
              title
              icon {
                url
                gatsbyImageData(height: 300)
              }
              description
              content {
                value
              }
            }
          }
          ... on DatoCmsTwoColumnContentImage {
            id: originalId
            __typename
            title
            specs {
              specs
            }
            image {
              url
              gatsbyImageData(height: 350)
            }
            content {
              value
              links {
                ... on DatoCmsElectronic {
                  __typename
                  id: originalId
                  title
                  slug
                }
              }
            }
            cta
            link {
              ... on DatoCmsCabinPage {
                id: originalId
              }
              ... on DatoCmsElectronicPage {
                id: originalId
              }
              ... on DatoCmsElectronic {
                id: originalId
              }
            }
          }
        }
      }
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }

    miscString: allDatoCmsMiscString(filter: { locale: { eq: $locale } }) {
      nodes {
        getInquiry
      }
    }
  }
`
